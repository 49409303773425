const state = {
  isCollapse: false,
  listKeepAlive: {
    isReset: true,
    isInitPageNo: true,
    isQueryList: true,
    isQuerySelect: true
  }
}

const mutations = {
  changeCollapse: (state, value) => {
    state.isCollapse = value
  },
  changeListKeepAlive: (state,  value) => {
    state.listKeepAlive.isReset = value.isReset
    state.listKeepAlive.isInitPageNo = value.isInitPageNo
    state.listKeepAlive.isQueryList = value.isQueryList
    state.listKeepAlive.isQuerySelect = value.isQuerySelect
  },
}

const actions = {
}

export default {
  state,
  mutations,
  actions
}
