import Vue from 'vue'
import App from './App.vue'
import 'babel-polyfill';

import router from './router'
import store from './store'

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

// style
import './assets/style/element-variables.scss'
import './assets/style/public.scss'
import './assets/style/layout.scss'
import './assets/style/shop.scss'

// element-ui
import { Container, Header, Aside, Main, Image, Menu, Submenu, MenuItem, Scrollbar, Avatar, Breadcrumb, BreadcrumbItem, Form, FormItem, Input, Button, Table, TableColumn, Pagination, Loading, Dialog, Select, Option, Tabs, TabPane, DatePicker, Cascader, Row, Col, Switch, Upload, Steps, Step, Tree, Checkbox, CheckboxGroup, Radio, RadioGroup, Carousel, CarouselItem, MessageBox, Message, InputNumber, Dropdown, DropdownMenu, DropdownItem, Tooltip, Badge} from 'element-ui';
Vue.use(Container).use(Header).use(Aside).use(Main).use(Image).use(Menu).use(Submenu).use(MenuItem).use(Scrollbar).use(Avatar).use(Breadcrumb).use(BreadcrumbItem).use(Form).use(FormItem).use(Input).use(Button).use(Table).use(TableColumn).use(Pagination).use(Loading.directive).use(Dialog).use(Select).use(Option).use(Tabs).use(TabPane).use(DatePicker).use(Cascader).use(Row).use(Col).use(Switch).use(Upload).use(Steps).use(Step).use(Tree).use(Checkbox).use(CheckboxGroup).use(Radio).use(RadioGroup).use(Carousel).use(CarouselItem).use(InputNumber).use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Tooltip).use(Badge);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;

Vue.config.productionTip = false;

Vue.prototype.$tinymce = tinymce;
Vue.use(VueTinymce);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
