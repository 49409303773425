const state = {
  storeGoodsType: '',
}

const mutations = {
  changeStoreGoodsType: (state, value) => {
    state.storeGoodsType = value
  }
}

const actions = {
}

export default {
  state,
  mutations,
  actions
}
