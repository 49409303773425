import Vue from 'vue'
import Router from "vue-router";
// import store from "../store";

import admin from "./admin";
import shop from "./shop";

Vue.use(Router);


//引入nprogress
import NProgress from 'nprogress' // 进度条
import 'nprogress/nprogress.css' //这个样式必须引入

export const constantRoutes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    component: () => import('../components/layout/shop/index'),
    meta: { title: '首页'},
    children: [
      {
        path: '/',
        meta: { title: '首页'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'Index',
            meta: { title: '首页', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/index/index')
          }
        ]
      }
    ]
  },  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index')
  },  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/register')
  },  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/login/forget')
  },  {
    path: '/pack',
    name: 'Pack',
    component: () => import('../views/login/pack')
  },
  ...admin,
  ...shop, {
    path: '/onLinePay',
    name: 'OnLinePay',
    meta: { title: '网银支付', menu: false},
    component: () => import('../views/shop/car/onLinePay')
  }, { path: '*', redirect: '/index' },
]

const createRouter = () => new Router({
  // mode: 'history',
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to) => {
  // if (!to.meta.isNeedKeepAlive) {
  //   store.commit('changeScrollTop')
  // }
  if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Forget' && to.name !== 'Pack') {
    sessionStorage.setItem('url', to.fullPath)
  }
  NProgress.done()
})

export default router
