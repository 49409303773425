const layout = () => import('../components/layout/admin/index')

const admin = [
  {
    path: '/admin/goods',
    component: layout,
    meta: { title: '商品管理', hidden: true},
    children: [
      {
        path: '/admin/goods/add',
        name: 'adminGoodsAdd',
        meta: { title: '发布商品', hidden: false},
        component: () => import('../views/admin/goods/add/first')
      }, {
        path: '/admin/goods/addInfo',
        name: 'adminGoodsAddInfo',
        meta: { title: '发布商品', hidden: false},
        component: () => import('../views/admin/goods/add/second')
      }, {
        path: '/admin/goods/addSuccess',
        name: 'adminGoodsAddSuccess',
        meta: { title: '发布商品', hidden: false},
        component: () => import('../views/admin/goods/add/third')
      }, {
        path: '/admin/goods/edit',
        name: 'adminGoodsEdit',
        meta: { title: '商品编辑', hidden: false},
        component: () => import('../views/admin/goods/edit')
      }, {
        path: '/admin/goods/sell',
        meta: { title: '出售中的商品', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminGoodsSell',
            meta: { title: '出售中的商品', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/goods/sell')
          }, {
            path: '/admin/goods/editSell',
            name: 'adminGoodsEditSell',
            meta: { title: '商品编辑', hidden: true, isBackKeepAlive: true},
            component: () => import('../views/admin/goods/edit')
          }
        ]
      }, {
        path: '/admin/goods/depot',
        meta: { title: '仓库中的商品', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminGoodsDepot',
            meta: { title: '仓库中的商品', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/goods/depot')
          }, {
            path: '/admin/goods/editDepot',
            name: 'adminGoodsEditDepot',
            meta: { title: '商品编辑', hidden: true, isBackKeepAlive: true},
            component: () => import('../views/admin/goods/edit')
          }
        ]
      }, {
        path: '/admin/goods/format',
        name: 'adminGoodsFormat',
        meta: { title: '规格管理', hidden: false},
        component: () => import('../views/admin/goods/format')
      }
    ]
  }, {
    path: '/admin/merchant',
    component: layout,
    meta: { title: '商家中心', hidden: true},
    children: [
      {
        path: '/admin/merchant/edit',
        name: 'adminMerchantEdit',
        meta: { title: '企业认证', hidden: false},
        component: () => import('../views/admin/merchant/edit/index')
      }, {
        path: '/admin/merchant/detail',
        name: 'adminMerchantDetail',
        meta: { title: '企业信息', hidden: false},
        component: () => import('../views/admin/merchant/detail')
      }
    ]
  }, {
    path: '/admin',
    component: layout,
    meta: { title: '订单中心', hidden: false},
    children: [
      {
        path: '/admin/order',
        meta: { title: '订单管理', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminOrderIndex',
            meta: { title: '订单管理', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/order/index')
          }, {
            path: '/admin/order/detail',
            name: 'adminOrderDetail',
            meta: { title: '订单详情', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/order/detail')
          }
        ]
      }
    ]
  }, {
    path: '/admin',
    component: layout,
    meta: { title: '退款维权', hidden: false},
    children: [
      {
        path: '/admin/refund',
        meta: { title: '退款维权', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminRefundIndex',
            meta: { title: '退款维权', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/refund/index')
          }, {
            path: '/admin/refund/detail',
            name: 'adminRefundDetail',
            meta: { title: '退款维权', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/refund/detail')
          }
        ]
      }
    ]
  }, {
    path: '/admin',
    component: layout,
    meta: { title: '结算管理', hidden: false},
    children: [
      {
        path: '/admin/settle',
        meta: { title: '结算管理', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminSettleIndex',
            meta: { title: '结算管理', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/settle/index')
          }, {
            path: '/admin/settle/wait',
            name: 'adminSettleWait',
            meta: { title: '待结算订单', hidden: true, isBackKeepAlive: true},
            component: () => import('../views/admin/settle/wait')
          }
        ]
      }
    ]
  }, {
    path: '/supplyBuying',
    component: layout,
    meta: { title: '供求管理', hidden: false},
    children: [
      {
        path: '/supplyBuying/buying',
        meta: { title: '采购管理', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'supplyBuyingBuying',
            meta: { title: '采购管理', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/buying/index')
          }, {
            path: '/supplyBuying/buying/add',
            name: 'supplyBuyingBuyingAdd',
            meta: { title: '发布采购单', hidden: false, isBackKeepAlive: false},
            component: () => import('../views/admin/supplyBuying/buying/add')
          }, {
            path: '/supplyBuying/buying/detail',
            name: 'supplyBuyingBuyingDetail',
            meta: { title: '报价单查询', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/buying/detail')
          }, {
            path: '/supplyBuying/buying/edit',
            name: 'supplyBuyingBuyingEdit',
            meta: { title: '编辑采购单', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/buying/edit')
          }
        ]
      }, {
        path: '/supplyBuying/supply',
        meta: { title: '供应管理', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'supplyBuyingSupply',
            meta: { title: '供应管理', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/supply/index')
          }, {
            path: '/supplyBuying/supply/add',
            name: 'supplyBuyingSupplyingAdd',
            meta: { title: '发布供应单', hidden: false, isBackKeepAlive: false},
            component: () => import('../views/admin/supplyBuying/supply/add')
          }, {
            path: '/supplyBuying/supply/edit',
            name: 'supplyBuyingSupplyingEdit',
            meta: { title: '编辑供应单', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/supply/edit')
          }, {
            path: '/supplyBuying/supply/detail',
            name: 'supplyBuyingSupplyingDetail',
            meta: { title: '查看供应单', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/supplyBuying/supply/detail')
          }
        ]
      }
    ]
  }, {
    path: '/admin',
    component: layout,
    meta: { title: '用户管理', hidden: false},
    children: [
      {
        path: '/admin/user',
        meta: { title: '用户管理', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminUserIndex',
            meta: { title: '用户管理', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/user/index')
          }, {
            path: '/admin/user/add',
            name: 'adminUserAdd',
            meta: { title: '添加用户', hidden: false, isBackKeepAlive: false},
            component: () => import('../views/admin/user/add')
          }
        ]
      }
    ]
  }, {
    path: '/admin',
    component: layout,
    meta: { title: '商家公告', hidden: false},
    children: [
      {
        path: '/admin/notice',
        meta: { title: '商家公告', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminNotice',
            meta: { title: '商家公告', hidden: true, isNeedKeepAlive: true},
            component: () => import('../views/admin/notice/index')
          }, {
            path: '/admin/notice/detail',
            name: 'adminNoticeDetail',
            meta: { title: '公告详情', hidden: false, isBackKeepAlive: true},
            component: () => import('../views/admin/notice/detail')
          }
        ]
      }
    ]
  }, {
    path: '/store',
    component: layout,
    meta: { title: '店铺管理', hidden: false},
    children: [
      {
        path: '/admin/store/backend',
        meta: { title: '店铺背景图', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminStoreBackend',
            meta: { title: '店铺背景图', hidden: true, isNeedKeepAlive: false},
            component: () => import('../views/admin/store/backend/index')
          }
        ]
      }, {
        path: '/admin/store/banner',
        meta: { title: '店铺banner', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminStoreBanner',
            meta: { title: '店铺banner', hidden: true, isNeedKeepAlive: false},
            component: () => import('../views/admin/store/banner/index')
          }
        ]
      }, {
        path: '/admin/store/floor',
        meta: { title: '店铺楼层', hidden: false},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'adminStoreFloor',
            meta: { title: '店铺楼层', hidden: true, isNeedKeepAlive: false},
            component: () => import('../views/admin/store/floor/index')
          }
        ]
      }
    ]
  }
]

export default admin
