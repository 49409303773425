const layout = () => import('../components/layout/shop/index')

const shop = [
  {
    path: '/shop',
    component: layout,
    meta: { title: '商城端'},
    children: [
      {
        path: '/shop/list',
        meta: { title: '商品列表'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopList',
            meta: { title: '商品列表', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/index/list')
          }, {
            path: '/shop/detail',
            name: 'shopDetail',
            meta: { title: '商品详情', menu: true, searchType: 1, isBackKeepAlive: true},
            component: () => import('../views/shop/index/detail')
          }
        ]
      }, {
        path: '/shop/search',
        meta: { title: '商品搜索'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopSearch',
            meta: { title: '商品搜索', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/index/search')
          }
        ]
      }, {
        path: '/shop/store',
        meta: { title: '店铺'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopStore',
            meta: { title: '店铺', menu: true, searchType: 4, isNeedKeepAlive: false},
            component: () => import('../views/shop/store/index')
          }, {
            path: '/shop/store/type',
            name: 'shopStoreType',
            meta: { title: '店铺', menu: true, searchType: 4, isNeedKeepAlive: true},
            component: () => import('../views/shop/store/type')
          }, {
            path: '/shop/store/search',
            name: 'shopStoreSearch',
            meta: { title: '店铺', menu: true, searchType: 4, isNeedKeepAlive: true},
            component: () => import('../views/shop/store/search')
          }
        ]
      }, {
        path: '/shop/help',
        meta: { title: '帮助中心'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopHelp',
            meta: { title: '帮助中心', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/help')
          }, {
            path: '/shop/article/detail',
            name: 'shopDetailArticle',
            meta: { title: '文章内容', menu: true, searchType: 1, isBackKeepAlive: true},
            component: () => import('../views/shop/detail')
          }
        ]
      }, {
        path: '/shop/news',
        meta: { title: '新闻资讯'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopNews',
            meta: { title: '新闻资讯', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/news')
          }
        ]
      }, {
        path: '/shop/cooperate',
        name: 'shopCooperate',
        meta: { title: '商务合作', menu: true, searchType: 1},
        component: () => import('../views/shop/cooperate')
      }, {
        path: '/shop/services',
        meta: { title: '增值服务', menu: true, searchType: 1},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopServices',
            meta: { title: '增值服务', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/service/index'),
          }, {
            path: '/shop/services/finance',
            name: 'shopServicesFinance',
            meta: { title: '供应链金融服务', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/service/finance'),
          }, {
            path: '/shop/services/taxation',
            name: 'shopServicesTaxation',
            meta: { title: '财税服务', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/service/taxation'),
          }, {
            path: '/shop/services/detect',
            name: 'shopServicesDetect',
            meta: { title: '检测服务', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/service/detect'),
          }, {
            path: '/shop/services/safe',
            name: 'shopServicesSafe',
            meta: { title: '保险服务', menu: true, searchType: 1, isNeedKeepAlive: false},
            component: () => import('../views/shop/service/safe'),
          }
        ]
      }, {
        path: '/shop/about',
        name: 'shopAbout',
        meta: { title: '关于我们', menu: true, searchType: 1},
        component: () => import('../views/shop/about')
      }, {
        path: '/shop/order',
        meta: { title: '我的订单'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopOrder',
            meta: { title: '我的订单', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/order/index')
          }, {
            path: '/shop/order/detail',
            name: 'shopOrderDetail',
            meta: { title: '订单详情', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/order/detail')
          }, {
            path: '/shop/order/upload',
            name: 'shopOrderUpload',
            meta: { title: '付款凭证上传', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/order/upload')
          }, {
            path: '/shop/order/refund',
            name: 'shopOrderRefund',
            meta: { title: '售后申请', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/order/refund')
          }, {
            path: '/shop/order/pay',
            name: 'shopOrderPay',
            meta: { title: '订单支付', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/order/pay')
          }, {
            path: '/shop/order/success',
            name: 'shopOrderSuccess',
            meta: { title: '支付成功', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/order/success')
          }
        ]
      }, {
        path: '/shop/refund',
        meta: { title: '退款维权'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopRefund',
            meta: { title: '退款维权', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/refund/index')
          }, {
            path: '/shop/refund/detail',
            name: 'shopRefundDetail',
            meta: { title: '退款维权', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/refund/detail')
          }
        ]
      }, {
        path: '/shop/address',
        name: 'shopAddress',
        meta: { title: '我的地址', menu: false},
        component: () => import('../views/shop/address/index')
      }, {
        path: '/shop/account',
        name: 'shopAccount',
        meta: { title: '账户中心', menu: false},
        component: () => import('../views/shop/account/index')
      }, {
        path: '/shop/password',
        name: 'shopPassword',
        meta: { title: '修改密码', menu: false},
        component: () => import('../views/shop/account/password')
      }, {
        path: '/shop/phone',
        name: 'shopPhone',
        meta: { title: '修改手机号码', menu: false},
        component: () => import('../views/shop/account/phone')
      }, {
        path: '/shop/accountPay',
        name: 'shopAccountPay',
        meta: { title: '修改支付密码', menu: false},
        component: () => import('../views/shop/account/pay')
      }, {
        path: '/shop/realName',
        name: 'shopRealName',
        meta: { title: '实名认证', menu: false},
        component: () => import('../views/shop/account/realName/index')
      }, {
        path: '/shop/realName/edit',
        name: 'shopRealNameEdit',
        meta: { title: '实名认证', menu: false},
        component: () => import('../views/shop/account/realName/edit')
      }, {
        path: '/shop/contract',
        name: 'shopContract',
        meta: { title: '我的合同', menu: false},
        component: () => import('../views/shop/contract/index')
      }, {
        path: '/shop/car',
        name: 'shopCar',
        meta: { title: '我的购物车', menu: false},
        component: () => import('../views/shop/car/index')
      }, {
        path: '/shop/car/confirm',
        name: 'shopCarConfirm',
        meta: { title: '确认订单', menu: false},
        component: () => import('../views/shop/car/confirm')
      }, {
        path: '/shop/car/upload',
        name: 'shopCarUpload',
        meta: { title: '生成订单/上传支付凭证', menu: false},
        component: () => import('../views/shop/car/upload')
      }, {
        path: '/shop/car/success',
        name: 'shopCarSuccess',
        meta: { title: '交易完成', menu: false},
        component: () => import('../views/shop/car/success')
      }, {
        path: '/shop/buying',
        meta: { title: '求购信息'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopBuying',
            meta: { title: '求购信息', menu: true, searchType: 2, isNeedKeepAlive: true},
            component: () => import('../views/shop/buying/index')
          }, {
            path: '/shop/buying/detail',
            name: 'BuyingDetail',
            meta: { title: '采购详情', menu: true, searchType: 2, isBackKeepAlive: true},
            component: () => import('../views/shop/buying/detail')
          }, {
            path: '/shop/buying/offer',
            name: 'BuyingOffer',
            meta: { title: '立即报价', menu: true, searchType: 2, isBackKeepAlive: true},
            component: () => import('../views/shop/buying/offer')
          }
        ]
      }, {
        path: '/shop/supply',
        meta: { title: '供应信息'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopSupply',
            meta: { title: '供应信息', menu: true, searchType: 3, isNeedKeepAlive: true},
            component: () => import('../views/shop/supply/index')
          }, {
            path: '/shop/supply/detail',
            name: 'SupplyDetail',
            meta: { title: '供应详情', menu: true, searchType: 3, isBackKeepAlive: true},
            component: () => import('../views/shop/supply/detail')
          }
        ]
      }, {
        path: '/shop/supplyBuying/quotation',
        meta: { title: '我的报价单'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopSupplyBuyingQuotation',
            meta: { title: '我的报价单', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/supplyBuying/quotation/index')
          }, {
            path: '/shop/supplyBuying/quotation/detail',
            name: 'shopSupplyBuyingQuotationDetail',
            meta: { title: '报价单详情', menu: false, isBackKeepAlive: true},
            component: () => import('../views/shop/supplyBuying/quotation/detail')
          }
        ]
      }, {
        path: '/shop/supplyBuying/attention',
        meta: { title: '关注的商家'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopSupplyBuyingAttention',
            meta: { title: '关注的商家', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/supplyBuying/attention/index')
          }
        ]
      }, {
        path: '/shop/keep',
        meta: { title: '我的收藏'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopKeep',
            meta: { title: '收藏的商品', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/keep/index')
          }, {
            path: '/shop/keep/store',
            name: 'shopKeep',
            meta: { title: '收藏的店铺', menu: false, isNeedKeepAlive: true},
            component: () => import('../views/shop/keep/store')
          }
        ]
      }, {
        path: '/shop/qualityTesting',
        meta: { title: '合作检测项目'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'shopQualityTesting',
            meta: { title: '合作检测项目', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/qualityTesting/index')
          }, {
            path: '/shop/shopQualityTesting/guoLian',
            name: 'shopQualityTestingGuoLian',
            meta: { title: '国联质检', menu: true, searchType: 1, isBackKeepAlive: true},
            component: () => import('../views/shop/qualityTesting/guoLian')
          }, {
            path: '/shop/shopQualityTesting/jianXun',
            name: 'shopQualityTestingJianXun',
            meta: { title: '检迅检测', menu: true, searchType: 1, isBackKeepAlive: true},
            component: () => import('../views/shop/qualityTesting/jianXun')
          }
        ]
      }, {
        path: '/shop/notice',
        meta: { title: '商城公告'},
        component: () => import('../components/layout/admin/children'),
        children: [
          {
            path: '/',
            name: 'ShopNotice',
            meta: { title: '商城公告', menu: true, searchType: 1, isNeedKeepAlive: true},
            component: () => import('../views/shop/notice/index')
          }, {
            path: '/shop/notice/detail',
            name: 'ShopNoticeDetail',
            meta: { title: '公告详情', menu: true, searchType: 1, isBackKeepAlive: true},
            component: () => import('../views/shop/notice/detail')
          }
        ]
      },
    ]
  }
]

export default shop
