<template>
<!--  <el-scrollbar class="page_main_scroll" ref="scroll">-->
<!--    <div id="app">-->
<!--        <router-view />-->
<!--    </div>-->
<!--  </el-scrollbar>-->
    <div id="app" ref="scroll" :class="{on: isIndex}">
        <router-view v-if="isRouteAlive" />
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'App',
    provide () {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        isIndex: false,
        isRouteAlive: true
      }
    },
    computed: {
      ...mapState({
        scrollTopNumber: state => state.user.scrollTopNumber,
      })
    },
    watch: {
      scrollTopNumber () {
        this.toScrollTop(0, 0)
      },
      $route () {
        this.getRouter()
      }
    },
    mounted() {
      this.getRouter()
    },
    methods: {
      getRouter () {
        if (this.$route.path === '/shop/detail') {
          this.isIndex = true
        } else {
          this.isIndex = false
        }
      },
      toScrollTop (number = 0, time) {
        if (!time) {
          document.body.scrollTop = document.documentElement.scrollTop = number;
          return number;
        }
        const spacingTime = 20;
        let spacingIndex = time / spacingTime;
        let nowTop = document.body.scrollTop + document.documentElement.scrollTop;
        let everTop = (number - nowTop) / spacingIndex;
        let that = this
        let scrollTimer = setInterval(() => {
          if (spacingIndex > 0) {
            spacingIndex--;
            that.toScrollTop(nowTop += everTop);
          } else {
            clearInterval(scrollTimer);
          }
        }, spacingTime);
      },
      reload () {
        this.isRouteAlive = false
        this.$nextTick (() => {
          this.isRouteAlive = true
        })
      }
    }
  }
</script>


