const state = {
  isLogin: false,
  userStatus: 0,
  defaultActive: '',
  isMenu: true,
  isShowClassOne: false,
  goodKeyword: '',
  buyingKeyword: '',
  supplyKeyword: '',
  scrollTopNumber: 0,
  noticeLength: 0,
  buyingLength: true
}

const mutations = {
  changeLoginType: (state, value) => {
    state.isLogin = value
  },
  changeUserStatus: (state, value) => {
    state.userStatus = value
  },
  changeDefaultActive: (state, value) => {
    state.defaultActive = value
  },
  changeIsMenu: (state, value) => {
    state.isMenu = value
  },
  changeIsShowClassOne: (state, value) => {
    state.isShowClassOne = value
  },
  changeGoodKeyword: (state, value) => {
    state.goodKeyword = value
  },
  changeBuyingKeyword: (state, value) => {
    state.buyingKeyword = value
  },
  changeSupplyKeyword: (state, value) => {
    state.supplyKeyword = value
  },
  changeScrollTop: (state) => {
    state.scrollTopNumber++
  },
  changeNoticeLength: (state, value) => {
    state.noticeLength = Number(value)
  },
  changeBuyingLength: (state, value) => {
    state.buyingLength = value
  }
}

const actions = {
}

export default {
  state,
  mutations,
  actions
}
